import { useEffect } from "react";
import Parser from "html-react-parser";
import Prism from "prismjs";
import "prismjs/themes/prism.min.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.min.css";
require("prismjs/components/prism-javascript");
require("prismjs/components/prism-bash");
require("prismjs/components/prism-json");
require("prismjs/components/prism-sql");
require("prismjs/components/prism-yaml");
require("prismjs/components/prism-c");
require("prismjs/components/prism-java");
require("prismjs/components/prism-markup");
require("prismjs/plugins/line-numbers/prism-line-numbers");

export function Post({ post }) {
  
  useEffect(() => {
    Prism.highlightAll();
  }, [post]);

  if (post.content !== undefined) {
    return (
      <div className="Post line-numbers" id={post.id}>
        {Parser(post.content)}
      </div>
    );
  }
  return (
    <div className="Post">
      post not found
    </div>
  );
}
