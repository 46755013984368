import "./App.css";

function HeadAbout() {
    return (
        <div className="MainLeft">
            <p>
                This is a personal blog of Kirill Mikhailov, AKA solarisforever.
            </p>
            <p>
                I work remotely as a Java senior backend developer at
                Benefactory LLC.
            </p>
            <p>I work with Java since 2003.</p>
            <p>This is my personal website that serves to:</p>
            <ul>
                <li>express myself</li>
                <li>record my weekly work activities</li>
                <li>remember nuances, pitfalls and challenge resolution.</li>
            </ul>

            <p>
                <a
                    rel="noreferrer"
                    href="https://github.com/kmikhailov-tmn"
                    target="_blank"
                >
                    My GitHub profile
                </a>
            </p>
            <p>
                <a href="oldsite.html">An old version of this site</a>
            </p>
        </div>
    );
}

export default HeadAbout;
