import { useParams } from "react-router-dom";
import { PostDto } from "./PostDto";

function SinglePostView() {
    let { postId } = useParams();
    return (
        <div className="SinglePostView">
            <main>
                <PostDto postId={postId} />
            </main>
            <footer>
                {" "}
                &copy; 2024 Kirill Mikhailov
                <a href="/"> Home </a>
            </footer>
        </div>
    );
}

export default SinglePostView;
