import "./Posts.css";
import { useEffect, useState } from "react";
import { Post } from "./Post";

export function Posts({ page, setPage }) {
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(
            "/posts.php?" +
                new URLSearchParams({
                    page: page,
                })
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText + " " + response.status);
                }
                return response.json();
            })
            .then((data) => {
                console.log("response " + data);
                setData(data);
            })
            .catch((err) => {
                console.error("Error:", err);
            });
    }, [page]);

    const nextPage = () => {
        setPage(page + 1);
        window.scrollTo(0, 0);
    };

    if (data.posts !== undefined) {
        return (
            <div className="Posts">
                {data.posts.map((post, index) => (
                    <Post key={index} post={post} />
                ))}
                {page > 0 ? (
                    <button onClick={() => setPage(page - 1)}>
                        Previous page
                    </button>
                ) : (
                    ""
                )}
                {page + 1 < data.totalPages ? (
                    <button onClick={nextPage}>Next page</button>
                ) : (
                    ""
                )}
            </div>
        );
    }
    return <div className="Posts">posts have not found</div>;
}
