import { useEffect, useState } from "react";
import { Post } from "./Post";

export function PostDto({ postId }) {
    const [postDto, setPostDto] = useState({});
    useEffect(() => {
        fetch(
            "/post.php?" +
                new URLSearchParams({
                    post_id: postId,
                })
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText + " " + response.status);
                }
                return response.json();
            })
            .then((data) => {
                console.log("response " + data);
                setPostDto(data);
            })
            .catch((err) => {
                console.error("Error:", err);
            });
    }, [postId]);

    if (postDto.post !== undefined) {
        return <Post post={postDto.post} />;
    }
    return <div className="Post">post not found</div>;
}
